<template>
  <div>
    <v-container id="bc-table" fluid tag="section">
      <base-material-card inline color="secondary" class="px-5 py-3 mb-5">
        <template v-slot:heading
          ><v-icon>mdi-account-multiple-check</v-icon></template
        >
        <template v-slot:after-heading
          ><h1 class="h5">
            <b>{{ $tc("background_check", 1) }}</b>
          </h1></template
        >
        <div v-if="!loading">
          <v-form
            @submit.stop.prevent="get_bcs_by_page()"
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-row justify="center" class="mt-6">
              <v-text-field
                class="mx-3"
                dense
                outlined
                :label="$t('email')"
                v-model="name_filter"
                style="max-width: 35%"
              ></v-text-field>
              <v-btn class="mx-3 secondary" type="submit">{{
                $t("apply")
              }}</v-btn>
            </v-row>
          </v-form>
          <v-simple-table>
            <thead>
              <tr>
                <th>{{ $t("email") }}</th>
                <th>{{ $t("cpf") }}</th>
                <th>{{ $t("last_update") }}</th>
                <th>{{ $t("status") }}</th>
                <th>{{ $t("result") }}</th>
                <th>{{ $t("go_to") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(bc, index) in bcs" :key="index">
                <td>{{ bc.User.Email }}</td>
                <td>{{ bc.User.SocialNumber }}</td>
                <td>{{ gs.convert_date(bc.LastUpdate, true) }}</td>
                <td>
                  {{
                    bc.Status == 0
                      ? $t("processing")
                      : bc.Status == 1
                      ? $t("under_analysis")
                      : $t("concluded")
                  }}
                </td>
                <td>
                  {{
                    bc.Result == 0
                      ? $t("not_reviewed")
                      : bc.Result == 1
                      ? $t("review")
                      : bc.Result == 2
                      ? $t("invalid")
                      : $t("valid")
                  }}
                </td>
                <td>
                  <v-btn
                    class="secondary"
                    @click="go_to_user_bc_page(bc.UserId)"
                    min-width="0"
                    small
                  >
                    <v-icon>mdi-arrow-right-bold</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-pagination
            color="secondary"
            @input="changePage"
            :length="total_pages"
            v-model="page"
          >
          </v-pagination>
        </div>
        <v-container v-else>
          <v-progress-circular
            style="margin-left: 50%"
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-container>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "BackgroundChecks",

  components: {},

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    page: 1,
    total_pages: 1,
    name_filter: "",
    valid: true,
    lazy: false,
    bcs: [],
  }),
  async created() {
    await this.get_bcs_by_page();
  },
  computed: {},
  methods: {
    go_to_user_bc_page(userId) {
      this.$router.push({ path: `/users/edit/${userId}/?tab=4` });
    },
    async get_bcs_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = { Page: page };
      if (this.name_filter) {
        params.NameFilter = this.name_filter;
      }
      if (this.orderBy != null) {
        params.Order = this.orderBy;
        params.OrderMode = this.orderByMode;
      }
      await this.apiService
        .getRequest("backgroundcheck/list/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp.message);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.bcs = json.BackgroundChecks;
        })
        .catch((error) => {});
      this.loading = false;
    },
    changePage() {
      this.$router.push({
        path: "/background-checks",
        query: { page: this.page },
      });
      this.get_bcs_by_page();
    },
  },
};
</script>
